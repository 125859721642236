import React from 'react'
import { Header } from './containers';
import { Navbar } from './components';

const Home = () => {
    return (
        <div className="App">
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>

        </div>
    )
}

export default Home
