import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import logo from '../../assets/logo.png';
import nftcalendarlogo from '../../assets/nftcalendarlogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './navbar.css';

const Navigation = () => {
  return (
    <Navbar expand="md" className="navbar-custom">
      <Navbar.Brand href="/">
        <img
          src={logo}
          alt="logo"
          width="35%"
          height="35%"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto px-5">
          <Nav.Link href="https://nftcalendar.io/"><img src={nftcalendarlogo} alt="nftcalendarlogo" size="3rem"/></Nav.Link>
          <Nav.Link href="https://discord.gg/fkVkZ5vXGS"><FaDiscord size="3rem"/></Nav.Link>
          <Nav.Link href="https://twitter.com/Cypher_face"><FaTwitter size="3rem"/></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
