import React, { useState, useEffect } from "react";
import nft from '../../assets/nft.png';
import nft1 from '../../assets/nft1.png';
import nft2 from '../../assets/nft2.png';
import nft3 from '../../assets/nft3.png';
import nft4 from '../../assets/nft4.png';
import './header.css';
import mint from '../../assets/mint.png';
import 'bootstrap/dist/css/bootstrap.min.css';



const Header = () => {
  const [currentClass, setCurrentClass] = useState(nft1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the current class every second
      setCurrentClass((prevClass) => {
        if (prevClass === nft1) {
          return nft2;
        } else if (prevClass === nft2) {
          return nft3;
        } else if (prevClass === nft3) {
          return nft4;
        } else {
          return nft1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
  <><header>
      <div class="row pt-0 m-0">
        <div class="text-center pt-5 col-12">
          <img class="slogan" src={nft} alt=""></img>
        </div>   
      </div>
      <div class="row pt-0 m-0">
        <div class="col-lg-3 col-md-6 col-6 py-5">
          <div class="py-5-0">
            <img class={`nft ${currentClass}`} src={currentClass} alt="" />
          </div>
        </div>
        <div class="d-lg-none col-md-6 col-6 py-5">
          <div class="py-5-0">
            <img class={`nft ${currentClass}`} src={currentClass} alt="" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12 py-5">
          <img class="mintnow pb-5" src={mint} alt=""></img>
          <div class="mintbutton py-5-0 mx-auto">
            <a class="d-flex justify-content-center" href="https://mint.cypherface.xyz/">
              <button type="button">
                M I N T
              </button>
            </a>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-3 col-md-3 col-6 py-5">
          <div class="py-5-0">
            <img class={`nft ${currentClass}`} src={currentClass} alt="" />
          </div>
        </div>

      </div>
    
    </header></>


  );
};

export default Header;
