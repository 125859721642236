import React from 'react';

import Home from './Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import './App.css';

const App = () => {
  return (
  <BrowserRouter> 
      <Routes>
        <Route path="/" exact element={<Home/>} />
      </Routes>
  </BrowserRouter>   
  )
};

export default App;
